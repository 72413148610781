<template>
    <v-container class="body-card">
        <v-row>
            <v-col cols="12" class="pb-0">
                <v-card 
                    class="mx-auto my-3 border-green">
                    <v-col justify="center">
                        <div cols="auto" class="text-center py-0 grey-text">
                            {{detail.routename}}
                        </div>
                    </v-col>
                    <v-col>
                        <v-row class="pa-0 px-3">
                            <div class="text-center py-0 text-color-route" style="flex: 1;">
                                <h2 class="text-bus">{{detail.original}}</h2>
                                <div class="caption font-weight-light mt-n2">{{detail.originalName}}</div>
                            </div>
                            <div class="d-flex align-center pa-0">
                                <img 
                                    width="30px"
                                    :src="require('@/assets/home/bus.svg')" />
                            </div>
                            <div class="text-center py-0 text-color-route"  style="flex: 1;">
                                <h2 class="text-bus">{{detail.destination}}</h2>
                                <div class="caption font-weight-light mt-n2">{{detail.destinationName}}</div>
                            </div>
                        </v-row>
                    </v-col>
                    <v-col>
                        <div class="text-center body-2 text-color-date font-weight-regular"><i>{{detail.date}}</i></div>
                    </v-col>
                    <!-- <v-col>
                        <div class="text-center font-invoice">{{detail.ticket}}</div>
                    </v-col> -->
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="py-0">
                <v-card 
                    class="mx-auto mb-3 border-green">
                    <v-row align="center" class="mt-3 mb-0 mx-0 border-bottom">
                        <v-col cols="12" class="py-0 black--text">
                            <h3>{{$t("TripInformation")}}</h3>
                            <div class="body-1 pb-3">
                                <span class="font-italic">{{$t("Time")}}</span> : {{detail.date}}<br/>
                                <span class="font-italic">{{$t("Price")}}</span> : {{ Intl.NumberFormat('de-DE', { style: 'currency', currency: detail.currency_code || 'USD' }).format(detail.price) }} <br/>
                                <!-- <span class="font-italic">{{$t("Route")}}</span> : {{detail.routeCode}} - {{detail.routename}} <br/> -->
                                <!-- <span class="font-italic">{{$t("LicensePlates")}}</span> : {{detail.busName}} <br/> -->
                                <!-- <span class="font-italic">{{$t("DriversName")}}</span> : {{JSON.stringify(detail.driverName).replace(',', ', ').replace('[', '').replace(']', '').replaceAll('"', '')}} <br/> -->
                                <!-- <span class="font-italic">{{$t("FromTheStation")}}</span> : {{detail.originalName}} <br/> -->
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="py-0">
                <v-card 
                    class="mx-auto border-green">
                    <v-row align="center" class="my-1">
                        <v-col cols="12" class="px-6 py-1">
                            <p class="subtitle-1 font-weight-bold mb-0">
                                <span class="route-code caption">{{detail.routeCode}}</span> {{detail.routename}}
                            </p>
                            <div class="text-justify caption line-height-normal" v-for="(route, index) in detail.description" :key="index" cols="12">
                                <span v-if="index == 0" class="body-2 font-weight-bold pb-3">{{detail.originalName}} - {{detail.destinationName}}</span>
                                <span v-else class="body-2 font-weight-bold pb-3">{{detail.destinationName}} - {{detail.originalName}}</span>
                                <p class="detail-route text-justify mb-0 pb-3">
                                {{ route.stop_in }}
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="my-0 py-2 text-center">
                            <div class="body-1 text-color-route font-italic">{{$t("TicketAlreadyUsed")}}</div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "TripDetail",
    props: {
        detail: {
            type: Object,
            required: true,
        },
    }
}
</script>

<style lang="scss" scoped>
.body-card {
    background-color: #f5fdf7;
    height: 100%;
    width: 100%;
    padding-top: 0;
}

.white-background {
    background: #fff;
}

.border-green {
    border: 1px solid #4BB14E;
}

.line-height-normal {
    line-height: normal;
}

.text-color-route {
    color: #45a648;
}

.text-color-date {
    color: #489E4B;
}

.font-invoice {
    font-size: 0.6rem !important;
}

.grey-text {
    color: #535353;
}

.text-bus {
    font-size: 26px;
    font-weight: 400;
}
</style>