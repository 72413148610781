<template>
    <div>
        <div v-if="$route.params.detail.ticket_type == '3'">
            <DetailTripMultdays :detail="$route.params.detail"/>
        </div>
        <div v-else>
            <DetailTrip :detail="$route.params.detail"/>
        </div>
    </div>
</template>

<script>
import DetailTripMultdays from "@/views/myTrip/detailTripMultidays.vue";
import DetailTrip from "@/views/myTrip/detailTrip.vue";
export default {
    name: "MyTripDetail",
    components: { DetailTripMultdays, DetailTrip },
    mounted() {
        if (!this.$route.params.detail) {
            this.$router.go(-1)
        }
    },
}
</script>